import React from 'react';
import BannerSectionStyle9 from '../Section/BannerSection/BannerSectionStyle9';
import Section from '../Section';
import BlogSectionStyle2 from '../Section/BlogSection/BlogSectionStyle2';
import Breadcrumb from '../Breadcrumb';
import { pageTitle } from '../../helpers/PageTitle';
const blogData = [
  {
    title: 'Revolutionizing Healthcare with Karisma: Our Mission and Vision',
    thumbUrl: 'images/blog/post_1.jpg',
    date: 'July 1',
    btnText: '',
    href: '',
    socialShare: true,
  },
  {
    title: 'From Source to Shelf: The Journey of Our Pharmaceuticals',
    thumbUrl: 'images/blog/post_2.jpg',
    date: 'July 2',
    btnText: '',
    href: '',
    socialShare: true,
  },
  {
    title: 'Spotlight on Innovation: Cutting-Edge Medical Equipment We Supply',
    thumbUrl: 'images/blog/post_3.jpg',
    date: 'July 3',
    btnText: '',
    href: '',
    socialShare: true,
  },
  {
    title: 'The Importance of Quality Assurance in Pharmaceuticals',
    thumbUrl: 'images/blog/post_4.jpg',
    date: 'July 4',
    btnText: '',
    href: '',
    socialShare: true,
  },
  {
    title: 'Health Tips: Leveraging Our Products for a Healthier Life',
    thumbUrl: 'images/blog/post_5.jpg',
    date: 'July 5',
    btnText: '',
    href: '',
    socialShare: true,
  },
  {
    title: 'Global Partnerships: Collaborating for Better Health',
    thumbUrl: 'images/blog/post_6.jpg',
    date: 'July 6',
    btnText: '',
    href: '',
    socialShare: true,
  },
  {
    title: 'Sustainable Healthcare: Our Commitment to the Environment',
    thumbUrl: 'images/blog/post_7.jpg',
    date: 'July 7',
    btnText: '',
    href: '',
    socialShare: true,
  },
  {
    title: 'Meet the Experts: Interviews with Our Key Team Members',
    thumbUrl: 'images/blog/post_8.jpg',
    date: 'July 8',
    btnText: '',
    href: '',
    socialShare: true,
  },
  {
    title: 'Success Stories: Transforming Lives with Karisma’s Products',
    thumbUrl: 'images/blog/post_9.jpg',
    date: 'July 9',
    btnText: '',
    href: '',
    socialShare: true,
  },
];

export default function Blog() {
  pageTitle('Blog');
  return (
    <>
      <Section topMd={170} bottomMd={96} bottomLg={70}>
        <Breadcrumb title="Our recent blogs" />
      </Section>
      <Section bottomMd={200} bottomLg={150} bottomXl={110}>
        <BlogSectionStyle2 data={blogData} />
      </Section>
      <Section className="cs_footer_margin_0">
        <BannerSectionStyle9
          title="Karisma Pharmaceuticals   <br />& Medical Equipment <br />Importer PLC"
          subTitle="Delivering quality pharmaceuticals and  <br />advanced medical equipment for a healthier world."
          imgUrl="/images/doctors/banner_img_3.png"
        />
      </Section>
    </>
  );
}
