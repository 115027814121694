import React from 'react';
import BannerSectionStyle5 from '../Section/BannerSection/BannerSectionStyle5';
import BannerSectionStyle4 from '../Section/BannerSection/BannerSectionStyle4';
import TeamSectionStyle2 from '../Section/TeamSection/TeamSectionStyle2';
import Section from '../Section';
import { pageTitle } from '../../helpers/PageTitle';
const teamData = [
  {
    imgUrl: '/images/doctors/Untitled-1.jpg',
    department: 'Medicine',
    name: 'Pharmaceuticals',
    designation: 'Imports',
    description:
      'At Karisma Pharmaceuticals & Medical Equipment Importer PLC, we import a wide range of high-quality pharmaceuticals to meet your healthcare needs.',
    social: [
      { icon: 'fa6-brands:facebook-f', href: '/about' },
      { icon: 'fa6-brands:linkedin-in', href: '/about' },
      { icon: 'fa6-brands:twitter', href: '/about' },
    ],
    category: 'medicine',
    href: '',
  },
  {
    imgUrl: '/images/doctors/Untitled-2.jpg',
    department: 'Medical Equipment',
    name: 'Diagnostic equipment',
    designation: 'Imports',
    description:
      'At Karisma Pharmaceuticals & Medical Equipment Importer PLC, we import advanced diagnostic equipment to ensure precise and reliable medical testing.',
    social: [
      { icon: 'fa6-brands:facebook-f', href: '/about' },
      { icon: 'fa6-brands:linkedin-in', href: '/about' },
      { icon: 'fa6-brands:twitter', href: '/about' },
    ],
    category: 'medical equipment',
    href: '',
  },
  {
    imgUrl: '/images/doctors/Untitled-3.jpg',
    department: 'Medical Equipment',
    name: 'Therapeutic Equipments',
    designation: 'Import',
    description:
      'At Karisma Pharmaceuticals & Medical Equipment Importer PLC, we import state-of-the-art therapeutic equipment to support effective patient treatment and recovery.',
    social: [
      { icon: 'fa6-brands:facebook-f', href: '/about' },
      { icon: 'fa6-brands:linkedin-in', href: '/about' },
      { icon: 'fa6-brands:twitter', href: '/about' },
    ],
    category: 'medical equipment',
    href: '',
  },
  {
    imgUrl: '/images/doctors/Untitled-4.jpg',
    department: 'Medical Equipment',
    name: 'Surgical Equipment',
    designation: 'Imports',
    description:
      'At Karisma Pharmaceuticals & Medical Equipment Importer PLC, we import high-quality surgical equipment to facilitate safe and efficient medical procedures.',
    social: [
      { icon: 'fa6-brands:facebook-f', href: '/about' },
      { icon: 'fa6-brands:linkedin-in', href: '/about' },
      { icon: 'fa6-brands:twitter', href: '/about' },
    ],
    category: 'medical equipment',
    href: '',
  },
  {
    imgUrl: '/images/doctors/Untitled-5.jpg',
    department: 'Medical Equipment',
    name: 'Patient monitoring Devices',
    designation: 'Imports',
    description:
      'A first-line medication for type 2 diabetes that helps control blood sugar levels.',
    social: [
      { icon: 'fa6-brands:facebook-f', href: '/about' },
      { icon: 'fa6-brands:linkedin-in', href: '/about' },
      { icon: 'fa6-brands:twitter', href: '/about' },
    ],
    category: 'medical equipment',
    href: '',
  },
  {
    imgUrl: '/images/doctors/Untitled-6.jpg',
    department: 'Medical Equipment',
    name: 'Medical Consumables',
    designation: 'Imports',
    description:
      'A blood thinner used to prevent and treat blood clots, reducing the risk of stroke and heart attack.',
    social: [
      { icon: 'fa6-brands:facebook-f', href: '/about' },
      { icon: 'fa6-brands:linkedin-in', href: '/about' },
      { icon: 'fa6-brands:twitter', href: '/about' },
    ],
    category: 'medical equipment',
    href: '',
  },
];

export default function Doctors() {
  pageTitle('Products');
  return (
    <>
      <BannerSectionStyle5
        bgUrl="/images/about/banner_bg.jpg"
        imgUrl="/images/doctors/banner_img.png"
        title="Discover Our Imported  <br />Medicines and Equipment"
        subTitle="Explore our imported medicines and cutting-edge equipment designed to elevate healthcare standards"
      />
      <Section topMd={65} bottomMd={200} bottomLg={150} bottomXl={110}>
        <TeamSectionStyle2 data={teamData} />
      </Section>
      <Section className="cs_footer_margin_0">
        <BannerSectionStyle4
          bgUrl="images/doctors/banner_bg_2.jpg"
          title="Elevating Healthcare <br />Standards Worldwide!"
          subTitle="Delivering top-notch medicines and cutting-edge medical equipment for a healthier tomorrow."
        />
      </Section>
    </>
  );
}
