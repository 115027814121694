import React from 'react';
import IconBoxStyle11 from '../../IconBox/IconBoxStyle11';
import Spacing from '../../Spacing';

export default function ContactInfoSection({ sectionTitle }) {
  return (
    <div className="container">
      <h2 className="cs_fs_72 mb-0">{sectionTitle}</h2>
      <Spacing md="70" lg="50" />
      <div className="row g-4 g-xl-3 g-xxl-5">
        <div className="col-xl-4">
          <IconBoxStyle11
            title="Phone"
            subTitle="+251939740000 | +251939760000"
            iconSrc="/images/contact/icon_1.svg"
          />
        </div>
        <div className="col-xl-4">
          <IconBoxStyle11
            title="Email"
            subTitle="info@karismapharma.com anetenhgizachew@karismapharma.com bethelhemabate@karismapharma.com"
            iconSrc="/images/contact/icon_2.svg"
          />
        </div>
        <div className="col-xl-4">
          <IconBoxStyle11
            title="Location"
            subTitle="Bedriya City Mall 12th Floor"
            iconSrc="/images/contact/icon_3.svg"
          />
        </div>
      </div>
      <Spacing md="35" />
      {/* Start Google Map */}
      <div className="cs_map">
        <iframe
          id="map"
          title="Google Map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3940.6112364457385!2d38.78581117514557!3d9.007872891052655!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b85ce3381afd9%3A0x6fd3435ea2423db3!2zQmVkcmlhIENpdHkgTWFsbCBsIEhheWEgSHVsZXQgbCDhiaDhi7XhiKrhi6sg4Yiy4YmyIOGInuGIjSBsIOGIg-GLqyDhiIHhiIjhibU!5e0!3m2!1sen!2set!4v1718802426942!5m2!1sen!2set"
          allowFullScreen
        />
      </div>
      {/* End Google Map */}
    </div>
  );
}
