import React from 'react';
import Hero from '../Hero';
import AboutSection from '../Section/AboutSection';
import BrandsSection from '../Section/BrandsSection';
import Banner from '../Section/BannerSection';
import Section from '../Section';
import FeaturesSection from '../Section/FeaturesSection';
import TestimonialSection from '../Section/TestimonialSection';
import BlogSection from '../Section/BlogSection';
import AppointmentSection from '../Section/AppointmentSection';
import FaqSection from '../Section/FaqSection';
import AwardSection from '../Section/AwardSection';
import DepartmentSection from '../Section/DepartmentSection';
import { pageTitle } from '../../helpers/PageTitle';
const featureListData = [
  {
    iconSrc: '/images/home_1/compassion.svg',
    title: 'Mission',
    subTitle:
      'To provide Ethiopia with top-quality pharmaceutical products and medical equipment, ensuring accessible and reliable healthcare solutions for all.',
  },
  {
    iconSrc: '/images/home_1/excellence.svg',
    title: 'Excellence',
    subTitle:
      'Bringing the worlds best pharmaceutical solutions to your doorstep, ensuring healthier lives globally.',
  },
  {
    iconSrc: '/images/home_1/integrity.svg',
    title: 'Vision',
    subTitle: `To be the foremost provider of innovative and trusted healthcare imports in Ethiopia, driving improved health outcomes across the nation.`,
  },
  {
    iconSrc: '/images/home_1/respect.svg',
    title: 'Respect',
    subTitle:
      'We conduct our business with respect and dignity for all individuals, regardless of their background, beliefs, or circumstances. We believe that every person deserves to be treated with compassion and kindness in all our interactions.',
  },
  {
    iconSrc: '/images/home_1/teamwork.svg',
    title: 'Teamwork',
    subTitle:
      'At Karisma, we believe in working collaboratively with our team members and other healthcare professionals to provide comprehensive and effective solutions for our clients.',
  },
];
const brandData = [
  // { imgUrl: 'images/1ogo.png', imgAlt: 'Brand' },
  // { imgUrl: 'images/1ogo.png', imgAlt: 'Brand' },
  // { imgUrl: 'images/1ogo.png', imgAlt: 'Brand' },
  // { imgUrl: 'images/1ogo.png', imgAlt: 'Brand' },
  // { imgUrl: 'images/1ogo.png', imgAlt: 'Brand' },
  // { imgUrl: 'images/1ogo.png', imgAlt: 'Brand' },
  // { imgUrl: 'images/1ogo.png', imgAlt: 'Brand' },
  // { imgUrl: 'images/1ogo.png', imgAlt: 'Brand' },
];
const faqData = [
  {
    title: 'What types of products does Karisma Pharmaceuticals import?',
    content:
      'Karisma Pharmaceuticals imports a wide range of high-quality pharmaceuticals and state-of-the-art medical equipment from trusted international manufacturers.',
  },
  {
    title: 'How can I place an order with Karisma Pharmaceuticals?',
    content:
      'You can place an order by contacting our sales team via phone, email, or through our websites contact form. Our team will guide you through the ordering process.',
  },
  {
    title: 'Are your pharmaceutical products compliant with international standards?',
    content:
      'Yes, all our pharmaceutical products are sourced from leading international manufacturers and comply with stringent quality control standards to ensure safety and efficacy.',
  },
  {
    title: 'Do you provide technical support for the medical equipment you supply?',
    content:
      ' Yes, we offer expert technical support and training for the proper use and maintenance of all medical equipment we supply.',
  },
  {
    title: 'How do you ensure the quality of your imported products?',
    content:
      'We have a rigorous selection process and quality control checks in place to ensure that all imported products meet international safety and efficacy standards.',
  },
  {
    title: 'What is your delivery time for orders?',
    content:
      'Delivery times vary depending on the product and location, but we strive to ensure prompt and reliable delivery to meet your needs. Please contact us for specific delivery time estimates.',
  },
  {
    title: 'Can Karisma Pharmaceuticals help with selecting the right products for my healthcare facility?',
    content:
      'Absolutely. Our experienced team provides expert advice and consultation to help you choose the most suitable pharmaceutical products and medical equipment for your needs.',
  },
  {
    title: 'What should I do if I have a problem with an order or product?',
    content:
      'If you encounter any issues with your order or product, please contact our customer support team immediately. We are committed to resolving any concerns promptly and effectively.',
  },
  {
    title: 'Does Karisma Pharmaceuticals offer community support or engagement programs?',
    content:
      'Yes, we actively engage in community outreach programs and work closely with healthcare providers and community leaders to support local healthcare needs.',
  },
  {
    title: 'How can I stay updated on new products and services from Karisma Pharmaceuticals?',
    content:
      'You can stay updated by following us on social media, or regularly visiting our website for the latest news and updates.',
  },
];
const blogData = [
  {
    title: 'The Advantages of Quality Pharmaceutical Imports for Your Pharmacy',
    thumbUrl: 'images/home_1/Downpic.cc-image566783318.jpg',
    date: 'June 20, 2024',
    href: '',
    socialShare: true,
  },
  {
    title: 'Affordable Pharmaceutical Solutions: Tips and Strategies for Your Pharmacy',
    thumbUrl: 'images/home_1/Downpic.cc-image478631070.png',
    date: 'June 24, 2024',
    href: '',
    socialShare: true,
  },
  {
    title: 'Understanding Common Prescription Medications: A Comprehensive Guide',
    thumbUrl: 'images/home_1/Downpic.cc-image494874364.jpg',
    date: 'June 20, 2024',
    href: '',
    socialShare: true,
  },
];
const awardData = [
  {
    title: 'Pharmacy Excellence Award',
    subTitle:
      'This award recognizes pharmacy import companies that have demonstrated excellence in leadership, strategic planning, customer and employee satisfaction, and operational efficiency.',
    iconUrl: 'images/icons/award.svg',
  },
  {
    title: 'Innovative Pharmacy Solutions Award',
    subTitle:
      'This award recognizes companies that have used advanced technology to improve pharmacy operations and reduce costs.',
    iconUrl: 'images/icons/award.svg',
  },
  {
    title: 'Top Quality Supplier Award',
    subTitle:
      'This recognition is given to pharmacy import companies that have achieved high ratings for product quality and safety across multiple categories.',
    iconUrl: 'images/icons/award.svg',
  },
  {
    title: 'Regulatory Compliance Gold Seal',
    subTitle:
      'This recognition is given to companies that have met rigorous standards for regulatory compliance and quality assurance in the pharmaceutical industry.',
    iconUrl: 'images/icons/award.svg',
  },
];
const departmentData = [
  {
    title: 'Cosmetics and Beauty Products',
    iconUrl: 'images/home_1/cosmetics-svgrepo-com.svg',
    href: '',
  },
  {
    title: 'Pharmaceutical Drugs',
    iconUrl: 'images/home_1/pharmaceutical-svgrepo-com.svg',
    href: '',
  },
  {
    title: 'Medical Supplies and Equipment',
    iconUrl: 'images/home_1/med-kit-svgrepo-com (1).svg',
    href: '',
  },
  {
    title: 'Vitamins and Nutritional Supplements',
    iconUrl: 'images/home_1/vitamins-vitamin-svgrepo-com (1).svg',
    href: '',
  },
  {
    title: 'Personal Care and Hygiene Products',
    iconUrl: 'images/home_1/hygiene-wash-hands-soap-svgrepo-com.svg',
    href: '',
  },
  {
    title: 'Health and Wellness Products',
    iconUrl: 'images/home_1/wellness-svgrepo-com.svg',
    href: '',
  },
];

export default function Home() {
  pageTitle('Home');
  return (
    <>
      <Hero
        title="Karismatic Imports, Exceptional Health Solutions"
        subTitle="Bringing the world's best pharmaceutical solutions to your doorstep, ensuring healthier lives globally."
        bgUrl="/images/home_1/hero_bg.png"
        imgUrl="/images/home_1/img.png"
        videoBtnText="See how we work"
        videoUrl="https://www.youtube.com/embed/VcaAVWtP48A"
        infoList={[
          {
            title: 'Phone 1',
            subTitle: '+251939740000',
            iconUrl: '/images/contact/icon_1.svg',
          },
          {
            title: 'Phone 2',
            subTitle: '+251939760000',
            iconUrl: '/images/contact/icon_1.svg',
          },
          {
            title: 'Location',
            subTitle: 'Addis Ababa, Ethiopia',
            iconUrl: '/images/icons/pin.svg',
          },
        ]}
        btnText="Contact Us"
        btnUrl="/contact"
      />
      {/* Start Feature Section */}
      <Section
        topMd={185}
        topLg={140}
        topXl={100}
        bottomMd={185}
        bottomLg={140}
        bottomXl={100}
      >
        <FeaturesSection sectionTitle="Our Values" data={featureListData} />
      </Section>
      {/* End Feature Section */}
      {/* Start About Section */}
      <Section>
        <AboutSection
          imgUrl="/images/home_1/Downpic.cc-1036131880.jpg"
          spiningImgUrl="/images/home_1/about_mini.svg"
          title="About Us"
          subTitle="KARISMA"
          featureList={[
            {
              featureListTitle:
                'Welcome to Karisma Pharmaceuticals',
              featureListSubTitle:
                'At Karisma Pharmaceuticals, we are dedicated to revolutionizing healthcare in Ethiopia by importing and distributing high-quality pharmaceuticals and state-of-the-art medical equipment. With a vision to bridge the gap between global medical innovations and local healthcare needs, we strive to deliver the best international medical advancements to our communities. Our commitment to excellence, integrity, and innovation drives us to ensure that every healthcare provider, from large hospitals to small clinics, has access to effective and reliable medical products. We believe that by providing top-tier pharmaceuticals and cutting-edge medical equipment, we can significantly enhance patient care and health outcomes across Ethiopia. We take immense pride in our robust network of global partnerships, enabling us to source the finest medical products from leading international manufacturers. Our rigorous selection and quality control processes ensure that every product we import meets stringent safety and efficacy standards. Understanding the unique challenges of the Ethiopian healthcare system, we offer tailored solutions that address immediate needs while promoting long-term sustainability. By combining global expertise with local insights, we deliver unparalleled value to our customers and the communities we serve, ensuring that high-quality healthcare is accessible to all.',
            },
          ]}
        />
      </Section>
      {/* End About Section */}
      {/* Start Departments Section */}
      <Section topMd={185} topLg={150} topXl={110}>
        <DepartmentSection
          sectionTitle="Imports"
          bgUrl="images/home_1/hero_bg.png"
          data={departmentData}
        />
      </Section>

      {/* End Departments Section */}
      {/* Start Award Section */}
      {/* <Section topMd={185} topLg={140} topXl={100}>
        <AwardSection sectionTitle="Achivements" data={awardData} />
      </Section> */}
      {/* End Award Section */}
      {/* Start Testimonial */}
      <Section
        topMd={185}
        topLg={140}
        topXl={100}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
          {/* <TestimonialSection
            sectionTitle="Some Reviews"
            sectionTitleDown="Of our clients"
          /> */}
      </Section>
      {/* End Testimonial */}
      {/* Start Banner Section */}
      <Section>
        <Banner
          bgUrl="images/home_1/hero_bg.png"
          imgUrl="images/home_1/Downpic.cc-image550459538.jpg"
          title="Don't Compromise on Quality!"
          subTitle="Contact us today to order top-notch pharmaceutical products for your pharmacy!"
        />
      </Section>
      {/* End Banner Section */}
      {/* Start Blog Section */}
      <Section topMd={190} topLg={145} topXl={105}>
        <BlogSection
          sectionTitle="Latest Update"
          sectionTitleUp="BLOG POSTS"
          data={blogData}
        />
      </Section>
      {/* End Blog Section */}
      {/* Start Appointment Section */}
      {/* <Section topMd={190} topLg={145} topXl={105} id="appointment">
        <AppointmentSection
          sectionTitle="Appointment"
          sectionTitleUp="BOOK AN"
          imgUrl="/images/home_1/appointment.jpeg"
        />
      </Section> */}
      {/* End Appointment Section */}
      {/* Start FAQ Section */}
      <Section topMd={190} topLg={145} topXl={105}>
        <FaqSection
          data={faqData}
          sectionTitle="Usually Asked"
          sectionTitleUp="What People"
        />
      </Section>
      {/* End FAQ Section */}
      {/* Start Brand Section */}
      <Section
        topMd={200}
        topLg={150}
        topXl={110}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <BrandsSection data={brandData} />
      </Section>
      {/* End Brand Section */}
    </>
  );
}
