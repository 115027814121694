import React from 'react';
import BannerSectionStyle3 from '../Section/BannerSection/BannerSectionStyle3';
import BannerSectionStyle4 from '../Section/BannerSection/BannerSectionStyle4';
import Section from '../Section';
import DepartmentSectionStyle2 from '../Section/DepartmentSection/DepartmentSectionStyle2';
import FeaturesSectionStyle2 from '../Section/FeaturesSection/FeaturesSectionStyle2';
import FunFactSection from '../Section/FunFactSection';
import TeamSection from '../Section/TeamSection';
import GallerySection from '../Section/GallerySection';
import AwardSectionStyle2 from '../Section/AwardSection/AwardSectionStyle2';
import { pageTitle } from '../../helpers/PageTitle';
const departmentData = [
  {
    title: 'Pharmaceutical Imports and Distribution',
    subTitle: `High-Quality Medications, Wide Availability, and Quality Control`,
    iconUrl: '/images/icons/calendar_white.svg',
    href: '',
  },

  {
    title: 'Medical Equipment Supply',
    subTitle:
      'State-of-the-Art Equipment, Diverse Selection, and Reliable Delivery',
    iconUrl: '/images/icons/calendar_white.svg',
    href: '',
  },
  {
    title: 'Customer Support and Consultation',
    subTitle:
      'Expert Advice, Technical Support, and Exceptional Service',
    iconUrl: '/images/icons/calendar_white.svg',
    href: '',
  },
  {
    title: 'Karisma Pharmaceuticals',
    subTitle:
      'At Karisma Pharmaceuticals, we are dedicated to being your trusted partner in the Ethiopian healthcare sector, continually striving to improve health outcomes and enhance the quality of lifefor all.',
    iconUrl: '/images/icons/calendar_white.svg',
    href: '',
  },

];

const featureListData = [
  {
    title: 'Commitment to Quality',
    subTitle:
      'At Karisma Pharmaceuticals, quality is our top priority. We import only the highest quality medications and medical equipment from trusted international manufacturers, ensuring that you receive safe and effective products every time.',
    iconUrl: 'images/icons/professional.svg',
  },
  {
    title: 'Extensive Product Range',
    subTitle:
      'Our comprehensive product portfolio includes essential and specialized medicines, as well as a diverse selection of state-of-the-art medical equipment. We cater to the varied needs of hospitals, clinics, and healthcare providers across Ethiopia.',
    iconUrl: 'images/icons/comprehensive.svg',
  },
  {
    title: 'Reliable and Timely Delivery',
    subTitle:
      'We understand the importance of timely delivery in healthcare. Our efficient logistics network ensures that your orders are delivered promptly and reliably, so you can focus on providing excellent patient care',
    iconUrl: 'images/icons/patient.svg',
  },
  {
    title: 'Expert Support and Consultation',
    subTitle:
      'Our team of experts is always available to provide advice and consultation on the selection and use of our products. We offer technical support and training to ensure that you can use our medical equipment effectively and safely.',
    iconUrl: 'images/icons/facilities.svg',
  },
  {
    title: 'Exceptional Customer Service',
    subTitle:
      'Customer satisfaction is at the heart of everything we do. Our dedicated support teams are here to address any inquiries or concerns, providing you with exceptional service and peace of mind.',
    iconUrl: 'images/icons/professional.svg',
  },
  {
    title: 'Regulatory Compliance',
    subTitle:
      'We adhere to stringent regulatory standards, ensuring that all our products meet both Ethiopian and international requirements. You can trust us to provide compliant, high-quality healthcare solutions',
    iconUrl: 'images/icons/professional.svg',
  },
  {
    title: 'Partner in Healthcare',
    subTitle:
      'Choosing Karisma Pharmaceuticals means partnering with a company committed to improving health outcomes in Ethiopia. We are dedicated to enhancing the quality of life through innovative and reliable healthcare solutions.',
    iconUrl: 'images/icons/professional.svg',
  },
];

const funFactData = [
  { number: '20+', title: 'Years of experience' },
  { number: '95%', title: 'Patient satisfaction rating' },
  { number: '5000+', title: 'Patients served annually' },
  { number: '10+', title: 'Healthcare providers on staff' },
  { number: '22+', title: 'Convenient locations in the area' },
];

const teamData = [
  {
    imgUrl: 'images/about/doctor_1.png',
    name: 'Dr. James Lee, MD',
    designation: 'Head of Cardiologist',
    description:
      'With expertise in managing complex heart conditions and performing advanced cardiac procedures',
    social: [
      { icon: 'fa6-brands:facebook-f', href: '/about' },
      { icon: 'fa6-brands:linkedin-in', href: '/about' },
      { icon: 'fa6-brands:twitter', href: '/about' },
    ],
  },
  {
    imgUrl: 'images/about/doctor_2.png',
    name: 'Dr. John Smith, MD',
    designation: 'Emergency Medicine Physician',
    description:
      'With expertise in treating acute illnesses and injuries in medicine physician',
    social: [
      { icon: 'fa6-brands:facebook-f', href: '/about' },
      { icon: 'fa6-brands:linkedin-in', href: '/about' },
      { icon: 'fa6-brands:twitter', href: '/about' },
    ],
  },
  {
    imgUrl: 'images/about/doctor_3.png',
    name: 'Dr. Susan Bones, MD',
    designation: 'Board-certified Pediatrician',
    description:
      'With experience in managing complex medical conditions in children',
    social: [
      { icon: 'fa6-brands:facebook-f', href: '/about' },
      { icon: 'fa6-brands:linkedin-in', href: '/about' },
      { icon: 'fa6-brands:twitter', href: '/about' },
    ],
  },
];

const galleryData = [
  { imgUrl: '/images/about/laboratory-3d-glassware.jpg' },
  { imgUrl: '/images/about/as123.jpg' },
  { imgUrl: '/images/about/Ethiopia-pharma-sector.jpg' },
  { imgUrl: '/images/about/oxygen-concentrator-invacare-perfecto-2.jpg' },
  { imgUrl: '/images/about/71coab145pl-sl1500-500x500.webp' },
  { imgUrl: '/images/about/Oceanus-1-3-MHz-Ultrasound-Therapy-Ultra-Rehab-Physiotherapy-For-Pain-Relief.jpg_.webp' },
  { imgUrl: '/images/about/0.jpg' },
  { imgUrl: '/images/about/photo_2024-06-19_14-28-50.jpg' },
  { imgUrl: '/images/about/photo_2024-06-19_14-29-40.jpg' },
  { imgUrl: '/images/about/photo_2024-06-19_14-30-18.jpg' },
  { imgUrl: '/images/about/photo_2024-06-19_14-30-20.jpg' },

];
const awardData = [
  {
    iconUrl: '/images/icons/award.svg',
    title: 'Malcolm Baldrige National Quality Award',
  },
  { iconUrl: '/images/icons/award.svg', title: 'HIMSS Davies Award' },
  {
    iconUrl: '/images/icons/award.svg',
    title: 'Healthgrades National’s Best Hospital',
  },
  {
    iconUrl: '/images/icons/award.svg',
    title: 'Joint Commission Gold Seal of Approval',
  },
];

export default function About() {
  pageTitle('About');
  return (
    <>
      <BannerSectionStyle3
        bgUrl="/images/about/banner_bg.jpg"
        imgUrl="/images/about/banner_img.png"
        title="Welcome to <br />Karisma Pharmaceutials and Medical Equipments Importer PLC"
        subTitle="Karismatic Imports, Exceptional Health Solutions"
      />
      <Section topMd={200} topLg={150} topXl={110}>
        <DepartmentSectionStyle2
          sectionTitle="Provided by Karisma Pharmaceuticals"
          sectionTitleUp="SERVICES"
          data={departmentData}
        />
      </Section>
      <Section topMd={175} topLg={125} topXl={85} bottomMd={100} bottomLg={110}>
        <FeaturesSectionStyle2
          sectionTitle="Why Choose Us"
          imgUrl="images/about/Untitled-5.jpg"
          data={featureListData}
        />
      </Section>
      {/* <Section>
        <FunFactSection
          bgUrl="images/about/fun_fact_bg.jpeg"
          data={funFactData}
        />
      </Section> */}
      {/* <Section topMd={190} topLg={145} topXl={105}>
        <TeamSection
          sectionTitle="Experts Doctor"
          sectionTitleUp="MEET OUR"
          data={teamData}
        />
      </Section> */}
      <Section topMd={170} topLg={120} topXl={80}>
        <GallerySection
          sectionTitle="Our Imports and <br />Latest Activities"
          sectionTitleUp="HAVE A LOOK AT"
          data={galleryData}
        />
      </Section>
      <Section
        topMd={190}
        topLg={145}
        topXl={105}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        {/* <AwardSectionStyle2
          sectionTitle="Winning Awards and <br />Recognition"
          sectionTitleUp="AWARDS"
          sectionSubTitle="We have been recognized for our commitment to <br />excellence in healthcare."
          data={awardData}
        /> */}
      </Section>
      <Section className="cs_footer_margin_0">
        <BannerSectionStyle4
          bgUrl="images/about/Untitled-6.jpg"
          title="Karismatic Imports, <br /> Exceptional Health Solutions"
          subTitle="At Karisma Pharmaceuticals, we are dedicated to providing <br/>the best healthcare 
          solutions to enhance patient<br/> care and improve health outcomes across Ethiopia"
          center
        />
      </Section>
    </>
  );
}
